@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@keyframes circle4 {
  0% {
    top: 138.17999999999998px;
    left: 138.17999999999998px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1.41px;
    left: -1.41px;
    width: 279.18px;
    height: 279.18px;
    opacity: 0;
  }
}

@keyframes circle3 {
  0% {
    top: 60.63px;
    left: 60.63px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1.935px;
    left: -1.935px;
    width: 125.13000000000001px;
    height: 125.13000000000001px;
    opacity: 0;
  }
}

@keyframes circle2 {
  0% {
    top: 49px;
    left: 49px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -2.5px;
    left: -2.5px;
    width: 103px;
    height: 103px;
    opacity: 0;
  }
}

@keyframes circle1 {
  0% {
    top: 102.41px;
    left: 102.41px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 9.405px;
    left: 9.405px;
    width: 186.01px;
    height: 186.01px;
    opacity: 0;
  }
}

.preloader {
  &__wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-size: cover;
    background: url('~assets/images/code.jpg') repeat center center;
    z-index: 1000;
  }

  &__text {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 64px;
    box-sizing: border-box;

    @media (min-width: 768px) {
      width: auto;
      align-items: flex-start;
      background: linear-gradient(-90deg, rgba(1, 7, 20, 0) 3.7%, #010714 39.35%);
    }

    & > div {
      font-family: 'Ubuntu', monospace;
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.01em;
      color: #ffffff;

      @media (min-width: 768px) {
        font-size: 36px;
      }
    }

    & > div:first-child:after {
      content: '.';
      animation: dots 1s steps(5, end) infinite;
    }

    & > div + div {
      color: #e69b43;
    }
  }

  &__circle {
    &__1 {
      position: absolute;
      z-index: 1;
      top: 30%;
      left: 50%;

      .ldio-twoy04cmnx div {
        position: absolute;
        border-width: 2.09px;
        border-style: solid;
        opacity: 1;
        border-radius: 50%;
        animation: circle1 3.2258064516129035s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }

      .ldio-twoy04cmnx div:nth-child(1) {
        border-color: #009cde;
      }

      .ldio-twoy04cmnx div:nth-child(2) {
        border-color: #ff9933;
        animation-delay: -1.6129032258064517s;
      }

      .loadingio-spinner-ripple-85417ffvqb7 {
        width: 209px;
        height: 209px;
        display: inline-block;
        overflow: hidden;
        background: rgba(255, 255, 255, 0);
      }

      .ldio-twoy04cmnx {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0;
      }

      .ldio-twoy04cmnx div {
        box-sizing: content-box;
      }
    }

    &__2 {
      position: absolute;
      z-index: 1;
      top: 30%;
      left: 70%;

      .ldio-cdyyalbjg2 div {
        position: absolute;
        border-width: 1px;
        border-style: solid;
        opacity: 1;
        border-radius: 50%;
        animation: circle2 3.2258064516129035s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }

      .ldio-cdyyalbjg2 div:nth-child(1) {
        border-color: #ff9933;
      }

      .ldio-cdyyalbjg2 div:nth-child(2) {
        border-color: #009cde;
        animation-delay: -1.6129032258064517s;
      }

      .loadingio-spinner-ripple-b2emzrsi7q {
        width: 76px;
        height: 76px;
        display: inline-block;
        overflow: hidden;
        background: none;
      }
      .ldio-cdyyalbjg2 {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(0.76);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */
      }
      .ldio-cdyyalbjg2 div {
        box-sizing: content-box;
      }
    }

    &__3 {
      position: absolute;
      z-index: 1;
      top: 60%;
      left: 30%;

      .ldio-cmyqamutjzc div {
        position: absolute;
        border-width: 3.87px;
        border-style: solid;
        opacity: 1;
        border-radius: 50%;
        animation: circle3 3.2258064516129035s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }

      .ldio-cmyqamutjzc div:nth-child(1) {
        border-color: #31576f;
      }

      .ldio-cmyqamutjzc div:nth-child(2) {
        border-color: #009cde;
        animation-delay: -1.6129032258064517s;
      }

      .loadingio-spinner-ripple-9bytqbxuzba {
        width: 129px;
        height: 129px;
        display: inline-block;
        overflow: hidden;
        background: none;
      }
      .ldio-cmyqamutjzc {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */
      }
      .ldio-cmyqamutjzc div {
        box-sizing: content-box;
      }
    }

    &__4 {
      position: absolute;
      z-index: 1;
      top: 10%;
      left: 0;

      @media (min-width: 768px) {
        left: 30%;
        top: 35%;
      }

      .ldio-7t3mhoh4ivf div {
        position: absolute;
        border-width: 2.82px;
        border-style: solid;
        opacity: 1;
        border-radius: 50%;
        animation: circle4 1.25s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }

      .ldio-7t3mhoh4ivf div:nth-child(1) {
        border-color: #ffffff;
      }

      .ldio-7t3mhoh4ivf div:nth-child(2) {
        border-color: #ff9933;
        animation-delay: -0.625s;
      }

      .loadingio-spinner-ripple-50k0gopa5dk {
        width: 282px;
        height: 282px;
        display: inline-block;
        overflow: hidden;
        background: none;
      }
      .ldio-7t3mhoh4ivf {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */
      }
      .ldio-7t3mhoh4ivf div {
        box-sizing: content-box;
      }
    }
  }
}
