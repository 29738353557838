/* latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'), url(ubuntu/UbuntuRegular.woff2) format('woff2');
}

/* latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: local('Ubuntu Medium'), local('Ubuntu-Medium'), url(ubuntu/UbuntuMedium.woff2) format('woff2');
}

/* latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'), url(ubuntu/UbuntuBold.woff2) format('woff2');
}
