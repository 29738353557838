@keyframes spinner {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.mapboxgl-map {
  z-index: 1 !important;
}

.circle-marker {
  cursor: pointer;

  &:hover {
    z-index: 100000 !important; // fix the zindex of the tooltip by forcing the zindex of the parent
  }

  &--is-web:hover > .circle-marker-tooltip {
    display: flex;
  }

  &-tooltip {
    position: absolute;
    bottom: 25px;
    background: #001529;
    backdrop-filter: blur(16px);
    border-radius: 3px;
    display: none;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 15px;
    transform: translateX(-50%);

    & > span:first-child {
      font-family: 'Ubuntu', monospace;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 100%;
      text-align: center;
      letter-spacing: 0.03em;
      color: #fffa54;
    }

    & > span:nth-child(2) {
      font-family: 'Avenir Next', monospace;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 100%;
      text-align: center;
      letter-spacing: -0.01em;
      color: #e5e5e5;
      margin-top: 8px;
    }

    & > span:nth-child(3) {
      font-family: 'Ubuntu', monospace;
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 100%;
      text-align: center;
      letter-spacing: 0.01em;
      color: #e5e5e5;
      margin-top: 2px;
    }

    &-spinner {
      width: 33px;
      height: 33px;
      display: inline-block;
      overflow: hidden;
      background: none;
      transform: scale(0.8);

      & > div {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(0.33);
        backface-visibility: hidden;
        transform-origin: 0 0;

        div {
          left: 45px;
          top: 3.5px;
          position: absolute;
          animation: spinner linear 1s infinite;
          background: white;
          width: 10px;
          height: 29px;
          border-radius: 5px / 5.51px;
          transform-origin: 5px 46.5px;
          box-sizing: content-box;

          &:nth-child(1) {
            transform: rotate(0deg);
            animation-delay: -0.8888888888888888s;
          }

          &:nth-child(2) {
            transform: rotate(40deg);
            animation-delay: -0.7777777777777778s;
          }

          &:nth-child(3) {
            transform: rotate(80deg);
            animation-delay: -0.6666666666666666s;
          }

          &:nth-child(4) {
            transform: rotate(120deg);
            animation-delay: -0.5555555555555556s;
          }

          &:nth-child(5) {
            transform: rotate(160deg);
            animation-delay: -0.4444444444444444s;
          }

          &:nth-child(6) {
            transform: rotate(200deg);
            animation-delay: -0.3333333333333333s;
          }

          &:nth-child(7) {
            transform: rotate(240deg);
            animation-delay: -0.2222222222222222s;
          }

          &:nth-child(8) {
            transform: rotate(280deg);
            animation-delay: -0.1111111111111111s;
          }

          &:nth-child(9) {
            transform: rotate(320deg);
            animation-delay: 0s;
          }
        }
      }
    }
  }

  .container {
    transform-origin: center;
    transition: all 0.25s;
  }

  .svg-wrapper {
    transition: all 0.25s;
    svg {
      position: absolute;
      .outer-circle {
        transition: all 0.25s;
        transform-origin: center;
      }
      @for $i from 0 through 20 {
        .inner-circle-mod-#{$i} {
          animation: circle-marker-animation #{($i * 0.1) + 8}s infinite;
          animation-delay: #{($i * 0.1)}s;
        }
      }
    }
  }

  h3 {
    pointer-events: none;
    transition: all 0.25s;
    position: absolute;
    border: 0;
    color: #fff;
    text-transform: capitalize;
    font-weight: normal;
    width: 100px;
    margin: 30px 0 0 -50px;
    text-align: center;
    font-size: 13px;
    text-shadow: 1px 1px 1px #000;
  }
  &:hover {
    .svg-wrapper {
      transform: scale(1.1, 1.1);
      transform-origin: center center;
      svg {
        position: absolute;
        .outer-circle {
          transform: scale(1) !important;
        }
        .inner-circle {
          animation: circle-marker-animation 1s infinite;
        }
      }
    }
    h3 {
      opacity: 1 !important;
    }
  }
}

@keyframes circle-marker-animation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
