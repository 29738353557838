#globe-continent-markers {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0; // start invisible, later to be set by setVisibility
  transition: opacity 0.5s;
  .continent-marker {
    position: absolute;
    cursor: pointer;
    // transition: all .1s;
    .svg-wrapper {
      transition: all 0.25s;
      svg {
        position: absolute;
        .inner-circle {
          animation: circle-marker-animation 4s infinite;
        }
        @for $i from 0 through 10 {
          .inner-circle:nth-child(#{$i + 1}n) {
            transition-delay: 0.5s * $i;
          }
        }
      }
    }
    h3 {
      position: absolute;
      margin: 0;
      border: 0;
      color: #fff;
      text-transform: capitalize;
      font-weight: normal;
      width: 100px;
      margin: 24px 0 0 -50px;
      text-align: center;
      font-size: 13px;
    }
    &:hover {
      .svg-wrapper {
        transform: scale(1.25, 1.25);
        transform-origin: center center;
        svg {
          position: absolute;
          .inner-circle {
            animation: circle-marker-animation 1s infinite;
          }
        }
      }
    }
  }

  @keyframes circle-marker-animation {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
}
