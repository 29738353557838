@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: normal;
  src: url('avenir/avenir-regular.woff2') format('woff2'), url('avenir/avenir-regular.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  src: url('avenir/avenir-medium.woff2') format('woff2'), url('avenir/avenir-medium.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 600;
  src: url('avenir/avenir-demi.woff2') format('woff2'), url('avenir/avenir-demi.woff') format('woff');
}
