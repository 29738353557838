@import 'assets/fonts/Ubuntu.css';
@import './assets/fonts/AvenirNext.css';

html,
body,
#root {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: 'Ubuntu', monospace;
  overflow: hidden;
}

body {
  overflow-x: auto;
}

#root {
  min-width: 1024px;
  position: relative;

  @media screen and (max-device-width: 1023px) {
    min-width: 320px;
  }
}

.loading-overlay {
  width: 100%;
  height: 100%;
}

@import './assets/styles/preloader';
